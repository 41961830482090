import { useCookieConfiguration } from '@kaliber/cookie-consent'

import { routeMap } from '/routeMap'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { useTranslate } from '/machinery/I18n'

import styles from './CookiePermissions.css'

const translations = {
  functionality_storage: {
    title: 'cookie-permission-title-functionality',
    description: 'cookie-permission-description-functionality'
  },
  analytics_storage: {
    title: 'cookie-permission-title-analytics',
    description: 'cookie-permission-description-analytics'
  },
  security_storage: {
    title: 'cookie-permission-title-security',
    description: 'cookie-permission-description-security'
  },
  ad_storage: {
    title: 'cookie-permission-title-ad',
    description: 'cookie-permission-description-ad'
  },
  personalization_storage: {
    title: 'cookie-permission-title-personalization',
    description: 'cookie-permission-description-personalization'
  },
}

export function CookiePermissions({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const availablePermissions = clientConfig.cookieConfiguration.availablePermissions
  const { __ } = useTranslate()
  const reportError = useReportError()

  const {
    configuration,
    updatePermission,
  } = useCookieConfiguration({
    availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {availablePermissions.map(({ name, required }) => (
        <Permission
          key={name}
          disabled={required}
          value={required || configuration?.permissions?.[name] === 'granted'}
          onChange={value => { updatePermission(name, value) }}
          title={__`${translations[name].title}`}
        >
          <p>{__`${translations[name].description}`}</p>
        </Permission>
      ))}
    </div>
  )
}

function Permission({ title, value, onChange, disabled, children }) {
  return (
    <div className={cx(styles.componentPermission, disabled && styles.isDisabled)} key={value}>
      <label className={styles.label}>
        <input
          type="checkbox"
          checked={value}
          onChange={() => onChange(!value)}
          className={styles.checkbox}
          {...{ disabled }}
        />
        <strong>
          {title}
        </strong>
      </label>

      {children && <div className={styles.description}>{children}</div>}
    </div>
  )
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
